/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBN3UjTZGKW5RsQ-Rafv7SgknbkgQitgps",
  "appId": "1:638439458504:web:a2e4d5dd01d1db374787fd",
  "authDomain": "schooldog-i-barrow-ga.firebaseapp.com",
  "measurementId": "G-N3R2TT9MZJ",
  "messagingSenderId": "638439458504",
  "project": "schooldog-i-barrow-ga",
  "projectId": "schooldog-i-barrow-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-barrow-ga.appspot.com"
}
